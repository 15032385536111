import React from 'react'

import Layout from '@components/layout/Layout'
import FaqGlossaryItem from '@components/faqs/faq-glossary-item/faq-glossary-item.component'

const GlossaryItemCSFHypocretin= ({ location }) => {
    return (
        <Layout location={location}>
            <FaqGlossaryItem term="csf-hypocretin-1" />
        </Layout>
    )
}

export default GlossaryItemCSFHypocretin